<template>
  <svg :width="width" :height="height" viewBox="0 0 46 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M32 41V36M24.25 26H8.75C6.69457 26 4.72333 27.0536 3.26992 28.9289C1.81652 30.8043 1 33.3478 1 36V41" :stroke="color" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16.7711 18.7707C21.6784 18.7707 25.6565 14.7926 25.6565 9.88537C25.6565 4.97811 21.6784 1 16.7711 1C11.8639 1 7.88574 4.97811 7.88574 9.88537C7.88574 14.7926 11.8639 18.7707 16.7711 18.7707Z" :stroke="color" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M43.0556 22.3242H29.5795C28.5162 22.3242 27.6543 23.1922 27.6543 24.2628V31.048C27.6543 32.1187 28.5162 32.9867 29.5795 32.9867H43.0556C44.1188 32.9867 44.9808 32.1187 44.9808 31.048V24.2628C44.9808 23.1922 44.1188 22.3242 43.0556 22.3242Z" :stroke="color" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M31.6523 22.3257V18.1792C31.6523 16.8046 32.1438 15.4862 33.0186 14.5142C33.8935 13.5422 35.08 12.9961 36.3172 12.9961C37.5543 12.9961 38.7409 13.5422 39.6157 14.5142C40.4905 15.4862 40.982 16.8046 40.982 18.1792V22.3257" :stroke="color" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
<script>
export default {
  name: 'PrivateVisitorIcon',
  props: {
    color: {
      type: String,
      default: '#3034F7'
    },
    width: {
      type: Number,
      default: 40
    },
    height: {
      type: Number,
      default: 40
    }
  }
}
</script>
