<template>
  <svg :width="width" :height="height" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.5 6.4H10.1L8.3 11.8L4.7 1L2.9 6.4H0.5" fill="none"/>
    <path d="M12.5 6.4H10.1L8.3 11.8L4.7 1L2.9 6.4H0.5" :stroke="color" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
<script>
export default {
  name: 'CobrowseIcon',
  props: {
    color: {
      type: String,
      default: '#ffffff'
    },
    width: {
      type: Number,
      default: 13
    },
    height: {
      type: Number,
      default: 13
    }
  }
}
</script>
