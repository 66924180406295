var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "content-loader",
    {
      attrs: {
        viewBox: "0 0 300 200",
        speed: 2,
        primaryColor: "#b4ccf3",
        secondaryColor: "#ecebeb",
      },
    },
    [
      _c("rect", {
        attrs: {
          x: "12",
          y: "11",
          rx: "3",
          ry: "3",
          width: "55",
          height: "14",
        },
      }),
      _c("rect", {
        attrs: {
          x: "12",
          y: "32",
          rx: "3",
          ry: "3",
          width: "82",
          height: "14",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }