<template>
  <content-loader viewBox="0 0 300 200" :speed="2" primaryColor="#b4ccf3" secondaryColor="#ecebeb">
    <rect x="12" y="11" rx="3" ry="3" width="55" height="14" />
    <rect x="12" y="32" rx="3" ry="3" width="82" height="14" />
  </content-loader>
</template>

<script>
import { ContentLoader } from 'vue-content-loader'
export default {
  name: 'PlaceholderTextSmall',
  components: {
    ContentLoader
  }
}
</script>

<style></style>
