export const gen2function = {
  methods: {
    async callGen2Function(name, payload, token, endpoint) {
      try {
        const url = `https://${name}${endpoint}`
        const headers = new Headers()
        headers.append('Content-Type', 'application/json')
        headers.append('Authorization', `Bearer ${token}`)

        const options = {
          headers,
          method: 'POST',
          body: JSON.stringify(payload)
        }

        const fetchResponse = await fetch(url, options)
        const response = await fetchResponse.json()
        return response
      } catch (error) {
        // eslint-disable-next-line
        console.log(error.message)
        return null
      }
    },
    async callHttpRequestGen2(name, payload, token, endpoint) {
      try {
        const url = `https://${name}${endpoint}`
        const headers = new Headers()
        headers.append('Content-Type', 'application/json')
        headers.append('Authorization', `Bearer ${token}`)

        const options = {
          headers,
          method: 'POST',
          body: JSON.stringify(payload)
        }
        const fetchResponse = await fetch(url, options)
        const response = await fetchResponse.json()
        return response.result
      } catch (error) {
        return null
      }
    },
    async callHttpRequestVega(name, payload, apiKey, endpoint) {
      try {
        const url = `https://${name}${endpoint}`
        const headers = new Headers()
        headers.append('Content-Type', 'application/json')
        headers.append('x-api-key', apiKey)
        const options = {
          headers,
          method: 'POST',
          body: JSON.stringify(payload)
        }

        const fetchResponse = await fetch(url, options)
        const response = await fetchResponse.json()

        return response.result ? response.result[0] : response
      } catch (error) {
        return null
      }
    }
  }
}
